.lds-dual-ring {
  display: inline-block;
  width: 46px;
  height: 46px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid rgb(19, 199, 223);
  border-color: rgb(19, 199, 223) transparent rgb(19, 199, 223) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
